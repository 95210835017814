export const adviserCognito = {
  cognitoUserPoolId: 'eu-west-1_1cCD8kMpg',
  cognitoIdentityPoolId: 'eu-west-1:a4920cd4-2636-47de-8aa2-c9d7188d87b8',
  cognitoAppClientId: '3vmevl26n2v25vb4336rft41b2',
  cognitoUserPoolDomain: 'theexeter-adviser-qa.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
  cognitoScope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
};

export const collegueCognito = {
  cognitoUserPoolId: 'eu-west-1_rw1yCgeyE',
  cognitoIdentityPoolId: 'eu-west-1:8c5fff36-0445-4d15-9f14-052ffb1c007a',
  cognitoAppClientId: 'od28jvf0q21ccun28tf0vfn5l',
  cognitoUserPoolDomain: 'theexeter-colleague-qa.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
  cognitoScope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
};

export const registrationEnabled = false;
export const googleAnalyticsTag = 'G-PD0M2WX1H9';
export const googleGtag = 'GTM-TFDQPZT4';
export const inactivityTimeout = '30'
export const authCookieExpiry = '60'

export default {};
